import React from "react"
import PropTypes from "prop-types"
const PageHero = ({ image, imageMobile, className }) => {
  return (
    <section className={ `page-hero ${className}` } >
      <img src={image.desktop || image.url} className={`img_header desktop w-full`} alt={image.asset_alt || ''} />
      <img src={imageMobile.mobile} className={`img_header mobile w-full`} alt={imageMobile.asset_alt || ''} />
    </section>
  )
}

PageHero.defaultProps = {
  className: '',
}

PageHero.propTypes = {
    image: PropTypes.object.isRequired,
    imageMobile: PropTypes.object.isRequired,
    className: PropTypes.string,
}
  
export default PageHero
