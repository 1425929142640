import React, { useState } from "react"
import PropTypes from "prop-types"

const VideoEmbed = ({ block_image, block_image__mobile, block_video }) =>  {
    const [
        isOpen,
        setIsOpen,
    ] = useState(false)

    const startVideo = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen)
    }

    const getVideoIFrameURL = (url) => {
        const baseYoutube = "https://www.youtube.com/embed/";
        let videoId = [];
        // let newUrl = url;
        if(url.includes('youtu')){
            if(url.includes('//youtu.be')){
                videoId = url.match(/youtu\.be\/([a-zA-Z0-9\-_]*)/);
            }else{
                videoId = url.match(/v=([a-zA-Z0-9\-_]*)/);
            }
            if(videoId && videoId[1]){
                return `${baseYoutube}${videoId[1]}`;
            }
        }
        return url;
    };

    const getImageURL = (image, fallback) => {
        if(image && image.url){
            return image.url;
        }
        return fallback;
    }

    const videoThumbnailURL__fallback = block_video && block_video.image ? block_video.image : false;
    const videoThumbnailURL__desktop = getImageURL(block_image[0], videoThumbnailURL__fallback);
    const videoThumbnailURL__mobile = getImageURL(block_image__mobile[0], videoThumbnailURL__desktop);

    return (
        <>
            {block_video && block_video.url &&
                <section className="video-container">
                    <div className="image w-full">
                        {!isOpen && block_image[0] ? 
                            <div className="image video-container__preview w-full">
                                <a href="!#" onClick={(e) => startVideo(e)} className="cursor-pointer flex flex-col relative w-full">
                                    <img src="/images/playbutton.png" className="playBtn absolute self-center" alt="" />
                                    {videoThumbnailURL__desktop &&
                                        <img src={videoThumbnailURL__desktop} className="desktop w-full" />
                                    }
                                    {videoThumbnailURL__mobile &&
                                        <img src={videoThumbnailURL__mobile} className="mobile w-full" />
                                    }
                                </a>
                            </div>
                        : 
                            <iframe
                                width="100%"
                                src={`${getVideoIFrameURL(block_video.url)}${isOpen ? "?autoplay=1" : ""}`}
                                height="40rem"
                                frameBorder="0"
                                allowFullScreen
                                allow={`accelerometer; encrypted-media; gyroscope; picture-in-picture ${isOpen ? "autoplay;" : ""}`}
                                className="videoEmbed"
                                title={block_video.title || ''}
                            />
                        }
                    </div>
                    {(block_video.title || block_video.description) && 
                        <div className="mt-8 m-auto mb-16 max-w-4xl px-8 text-center lg:text-left">
                            {block_video.title && 
                                <h5 className="text-bold" >
                                    {block_video.title}
                                </h5>
                            }
                            {block_video.description && 
                                <p className="text my-0" >
                                    {block_video.description}
                                </p>
                            }
                        </div>
                    }
                </section>
            }
        </>
    );
    
}

VideoEmbed.propTypes = {
    block_video: PropTypes.object,
    block_image: PropTypes.array,
    block_image__mobile: PropTypes.array,
}

VideoEmbed.defaultProps = {
    block_video: {},
    block_image: [],
    block_image__mobile: [],
}

export default VideoEmbed

import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoVideoFragment on Craft_blocks_video_BlockType {
		typeHandle
        block_video {
            url
            image
            title
            description
            width
            height
        }
        block_image {
            id
            url
        }
        block_image__mobile {
            id
            url(handle: "medium")
        }
	}
	fragment NeoPostVideoEmbedFragment on Craft_post_content_videoEmbed_BlockType {
		typeHandle
        block_video {
            url
            image
            title
            description
            width
            height
        }
        block_image {
            id
            url
        }
        block_image__mobile {
            id
            url(handle: "medium")
        }
	}
`