import React from "react"
import PropTypes from "prop-types"
import Parser from "html-react-parser"

const TextContent = ({ block_textContent }) => {
    return (
        <div className={`block block-textContent max-w-3xl m-auto px-8`}>
            {Parser(block_textContent || '')}
        </div>
    );
}

TextContent.propTypes = {
    data: PropTypes.object.isRequired,
    block_textContent: PropTypes.string,
}

export default TextContent

import { graphql } from 'gatsby'
export const query = graphql`
	fragment NeoTextContentFragment on Craft_blocks_textContent_BlockType {
		typeHandle
		block_textContent
	}
`