import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageHero from "../components/images/pageHero"
import NeoBlocks from "../components/neo/blocks"

export const query = graphql`
	query ($id: [Craft_QueryArgument], $language: [String]) {
		craft {
			entry(id: $id, site: $language) {
				... on Craft_page_page_Entry {
					title
					post_accentColor
					post_featureImage {
						id
						url
						... on Craft_assets_Asset {
							desktop: url(handle: "hero")
							mobile: url(handle: "medium")
							asset_alt
						}
					}
					post_scripts_styles {
						id
						kind
						url
						mimeType
        		extension
					}
					blocks {
						...NeoBlocksFragment
					}
				}
				... on Craft_home_home_Entry {
					post_scripts_styles {
						id
						kind
						url
						mimeType
        		extension
					}
					blocks {
						...NeoBlocksFragment
					}
				}
			}
		}
	}
`

const Page = ({ data, pageContext }) => {
	const { globalData } = pageContext

	const { 
		title,
		blocks,
		post_accentColor, 
		post_featureImage, 
		post_scripts_styles,
	} = data.craft.entry

	console.log('post_scripts_styles', post_scripts_styles);

	return (
		<Layout { ...globalData} ressourceAssets={post_scripts_styles} >
			<div className={`page-container title-color-container relative block p-0 ${post_accentColor || ''}`} >
				{(post_featureImage && post_featureImage[0]) && 
					<PageHero 
						image={post_featureImage[0]}
						imageMobile={post_featureImage[1] ? post_featureImage[1] : post_featureImage[0] }
					/>
				}
				{title && 
					<section className={`intro-block max-w-5xl m-auto px-8`}>
						<h1 className={`intro-block-title title text-center text-4xl lg:text-5xl my-8`}>{title}</h1>
					</section>
				}
				<NeoBlocks
					blocks={blocks}
					globProps={globalData.globProps}
				/>
			</div>
		</Layout>
	)
}

Page.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
}

export default Page