import React from "react"
import PropTypes from "prop-types"
import BlocksContent from "./blocksContent"

class NeoBlocks extends React.Component {
    constructor(props){
		super(props);
		this.getData = this.getData.bind(this);
		this.getGlobalMarkdown = this.getGlobalMarkdown.bind(this);
		this.getGlobalAsset = this.getGlobalAsset.bind(this);
	}

	getData(key) {
		return this.props.globProps.find(x => x.props_key == key) || {};
	}
	getGlobalMarkdown(key) {
		return this.getData(key).block_markdown || ``;
	}
	getGlobalAsset(key, mobile) {
		const asset = this.getData(key) || null;
		if(asset){
			console.log('getGlobalAsset', asset.block_image);
			if(mobile && asset.block_image__mobile){
				return asset.block_image__mobile;
			}
			if(asset.block_image){
				return asset.block_image;
			}
		}
		return {};
	}

	render(){
		const { blocks } = this.props;

		return (
			<BlocksContent 
				data={{
					getData: this.getData,
					getGlobalAsset: this.getGlobalAsset,
					getGlobalMarkdown: this.getGlobalMarkdown,
				}} 
				blocks={blocks || []} 
			/>
		);

	}
}

NeoBlocks.propTypes = {
	blocks: PropTypes.array,
	globProps: PropTypes.array,
}

export default NeoBlocks


