import React from "react"
import PropTypes from "prop-types"
import Parser from "html-react-parser"
import RenderMarkdown from "../../../../core/renderMarkdown"

class MediaAndContent extends React.Component {

    constructor(props){
		super(props);
		this.getImage = this.getImage.bind(this);
		this.getTextContent = this.getTextContent.bind(this);
	}

	getImage({index = 0, size = 'url', className = ''}) {
        const { block_images } = this.props;
        return (
            <>  
                {block_images && block_images[index] && 
                    <img 
                        src={block_images[index][size]} 
                        className={className} 
                        alt={block_images[index].asset_alt || ''} 
                    />
                }
            </>
        )
    }
    
	getTextContent() {
        return Parser(this.props.block_textContent || '');
    }

    render() {
        // const defaultBlockMarkdown = `<section className="relative w-5/6 max-w-5xl mx-auto">{props.func.getTextContent()}</section>`;
        const { block_markdown, block_textContent, data } = this.props;
        if(!block_markdown || block_markdown.trim() === ''){
            return (
                <>
                    {block_textContent && block_textContent.trim() !== '' && 
                        <section className="relative w-5/6 max-w-5xl mx-auto">
                            {this.getTextContent()}
                        </section>
                    }
                </>
            );
        }
        return (
            <>
                <RenderMarkdown 
                    md={block_markdown} 
                    func={{
                        ...data,
                        getImage: this.getImage,
                        getTextContent: this.getTextContent,
                    }}
                />
            </>
        );
    }
}

MediaAndContent.propTypes = {
    data: PropTypes.object.isRequired,
    block_markdown: PropTypes.string.isRequired,
    block_images: PropTypes.array.isRequired,
    block_textContent: PropTypes.string,
};

MediaAndContent.defaultProps = {
    block_markdown: ` `,
    block_textContent: ` `,
    block_images: [],
};

export default MediaAndContent;


import { graphql } from 'gatsby';
export const query = graphql`
	fragment NeoMediaAndContentFragment on Craft_blocks_mediaAndContent_BlockType {
		typeHandle
		block_markdown
		block_textContent
        block_images {
            id
            url
            ... on Craft_assets_Asset {
                large: url(handle: "large")
                medium: url(handle: "medium")
                asset_alt
            }
        }
	}
    fragment NeoMarkdownFragment on Craft_blocks_markdown_BlockType {
		typeHandle
		block_markdown
        block_textContent
        block_images {
            id
            url
            ... on Craft_assets_Asset {
                asset_caption
                asset_alt
            }
        }
	}
`