import React from "react"
import PropTypes from "prop-types"
import Parser from "html-react-parser"
import Glide from "@glidejs/glide"
import "../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css"
import "./slider.css"

const Slide = ({ image, mobileImage, content }) => {
    return (
        <li className="glide__slide">
            <div className="absolute z-10 flex flex-col text-center slide-text-container">
                {Parser(content)}
            </div>

            {image && image.mimeType && image.mimeType.includes('video') ?
                <video className="w-full desktop" autoPlay muted loop>
                    <source src={image.url} type={image.mimeType} />
                </video>
            :
                <img src={image.url}  alt={image.asset_alt} className="w-full desktop" />
            }
            {mobileImage && mobileImage.mimeType && mobileImage.mimeType.includes('video') ?
                <video className="w-full mobile" autoPlay muted loop>
                    <source src={mobileImage.url} type={mobileImage.mimeType} />
                </video>
            :
                <img src={mobileImage.url}  alt={mobileImage.asset_alt} className="w-full mobile" />
            }
        </li>
    );
}

Slide.propTypes = {
    image: PropTypes.object.isRequired,
    mobileImage: PropTypes.object.isRequired,
    content: PropTypes.string.isRequired,
}


class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.bulletActive = this.bulletActive.bind(this);
        this.videoActiveAutoPlay = this.videoActiveAutoPlay.bind(this);
    }

    componentDidMount() {
        const { perView, id } = this.props

        this.glide = new Glide(`.glide_${id}`, { perView })
        this.glide.mount()
        this.glide.on(`move.after`, () => {
            this.videoActiveAutoPlay()
            this.bulletActive()
        })
        this.videoActiveAutoPlay()
        this.bulletActive()
    }

    componentWillUnmount() {
        this.glide.destroy()
    }

    videoActiveAutoPlay = () => {
        const { id } = this.props;
        const glideSlider = document.getElementById(`glide_${id}`);
        const hideSize = (window.innerWidth >= 680 ? 'mobile' : 'desktop');
        
        const allVideosAutoplay = glideSlider.querySelectorAll('.glide__slide:not(.glide__slide--active) video, .glide__slide.glide__slide--active video.' + hideSize + '');
        for (let x = 0; x < allVideosAutoplay.length; x++) {
            allVideosAutoplay[x].pause(); 
        }
  
        const video = glideSlider.querySelector('.glide__slide.glide__slide--active video:not(.' + hideSize + ')[autoplay]');
        if(video){
            video.play();
        }
    }

    bulletActive = () => {

        const { id } = this.props
        const glideSlider = document.getElementById(`glide_${id}`);
        
        const glideSlide = glideSlider.getElementsByClassName(`glide__slide`)
        const glideBullet = glideSlider.getElementsByClassName(`glide__bullet`)
      
        if (glideSlider.classList.contains(`glide--swipeable`)) {
            for (let i = 0; i < glideSlide.length; i++) {
                glideBullet[i].classList.remove(`glide__bullet__focus`)
                if (glideSlide[i].classList.contains(`glide__slide--active`)) {
                    glideBullet[i].classList.add(`glide__bullet__focus`)
                }
            }
        }
    }

    render() {

        const { block_slider, id } = this.props
        return (
            <section className="block_section__slider">
                <div className="block__slider">
                    <div id={`glide_${id}`} className={` glide glide_${id}`}>
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {block_slider.map((slide, key) => (
                                    <Slide 
                                        image={slide.slide_image[0] || {}}
                                        mobileImage={slide.slide_mobileImage[0] || {}}
                                        content={slide.slide_content || ''}
                                        key={key}
                                    />
                                ))}
                            </ul>
                        </div>
                        <div data-glide-el="controls">
                            {block_slider.map((_, index) => (
                                <button key={index} data-glide-dir={'=' + index} className="glide__bullet" />
                            ))}
                            <button className="controls__button controls__button-left glide__arrow--left" data-glide-dir="<" />
                            <button className="controls__button controls__button-right glide__arrow--right" data-glide-dir=">" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Slider.propTypes = {
    data: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    block_slider: PropTypes.array.isRequired,
    perView: PropTypes.number,
}

Slider.defaultProps = {
  perView: 1
}

export default Slider

import { graphql } from "gatsby"
export const query = graphql`
	fragment NeoSliderFragment on Craft_blocks_slider_BlockType {
		typeHandle
        id
		block_slider {
            ... on Craft_block_slider_slide_BlockType {
                slide_content
                slide_image {
                    id
                    url
                    kind
                    mimeType
                    ... on Craft_assets_Asset {
                        asset_caption
                        asset_alt
                        img
                    }
                }
                slide_mobileImage {
                    id
                    url
                    kind
                    mimeType
                    ... on Craft_assets_Asset {
                        asset_caption
                        asset_alt
                        img
                    }
                }
            }
        }
	}
`