import React from "react"
import PropTypes from "prop-types"
import TextContent from "./types/textContent"
import Slider from "./types/slider"
import MediaAndContent from "./types/mediaAndContent"
import GallerySlider from "./types/gallerySlider"
import VideoEmbed from "./types/video"
import Links from "./types/links"

const components = {
	markdown: MediaAndContent,
	textContent: TextContent,
	slider: Slider,
	mediaAndContent: MediaAndContent,
	gallerySlider: GallerySlider,
	video: VideoEmbed,
	links: Links,
}

const Block = ({ block, data }) => {
	const type = block.typeHandle
	const Component = components[type]

	if (Object.keys(components).includes(type)) {
		return <Component {...block} data={data} />
	} else {
		return null
	}
}

Block.propTypes = {
	block: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
}

const BlocksContent = ({ blocks, ...props }) => (
	<>
		{blocks && blocks.map((block, i) => (
			<Block 
				key={i} 
				block={block} 
				{...props} 
			/>
		))}
	</>
)


BlocksContent.propTypes = {
	blocks: PropTypes.array,
	data: PropTypes.object.isRequired,
}

export default BlocksContent


import { graphql } from "gatsby"
export const query = graphql`
	fragment NeoHelperImageFragment on Craft_assets_Asset {
    	asset_caption
    	asset_alt
    	img
    	mimeType
    	url
    }
	fragment NeoBlocksFragment on Craft_blocks_NeoField {
		...NeoMarkdownFragment
		...NeoTextContentFragment
		...NeoSliderFragment
		...NeoMediaAndContentFragment
		...NeoGallerySliderFragment
		...NeoLinksFragment
		... on Craft_blocks_video_BlockType {
			typeHandle
			block_video {
				url
				image
				title
				description
			}
			block_image {
				id
				url
			}
			block_image__mobile {
				id
				url(handle: "medium")
			}

		}
		# ...NeoVideoFragment
  	}
`