import React from "react"
import PropTypes from "prop-types"
import Parser from "html-react-parser"

const Link = ({ url, label, option_underlineColor }) => {

    return (
        <a href={url} className={`ardene-btn brand-cta font-bold text-base flex flex-col items-center m-20 link_underline ${option_underlineColor || 'accent-underline'}`}>
            {Parser(label)}
        </a>
    );
}

Link.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    option_underlineColor: PropTypes.string,
}

const Links = ({ block_links }) => {

        return (
            <section className="my-8 flex flex-col items-center">
                <div className="flex flex-col lg:flex-row ">
                    {block_links && block_links.map((link, index) => (
                        <Link {...link} key={index} /> 
                    ))}
                </div>
            </section>
        )
}

Links.propTypes = {
    data: PropTypes.object.isRequired,
    block_links: PropTypes.array.isRequired,
}

export default Links

import { graphql } from "gatsby"
export const query = graphql`
	fragment NeoLinksFragment on Craft_blocks_links_BlockType {
		typeHandle
        id
		block_links: children {
            ... on Craft_blocks_link_BlockType {
                label: block_link__label
                url: block_link__url
                option_underlineColor
            }
        }
	}
`